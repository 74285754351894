import { SWAP_TOKO_USER_TRANSFER_STATUS, SWAP_TOKO_STAKING_STATUS } from '@/utils/key-mapping'

export default {
  methods: {
    formatSwapTokoStatus(status) {
      let statusEl

      switch (status) {
        case SWAP_TOKO_USER_TRANSFER_STATUS.invalid:
          statusEl =
            '<span class="text-xs">Request</span><br><p class="text-bold text-uppercase text-error">Invalid</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.userTransferInit:
          statusEl = '<span class="text-xs">Request</span><br><p class="text-bold text-uppercase">Initial</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.userTransferPending:
          statusEl =
            '<span class="text-xs text-warning">Request</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.userTransferSuccess:
          statusEl = '<p class="text-bold text-uppercase text-success">Need Review</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.userTransferFailed:
          statusEl =
            '<span class="text-xs text-error">Request</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.adminReject:
          statusEl = '<p class="text-bold text-error text-uppercase">Rejected</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.adminApprove:
          statusEl = '<span class="text-xs">Approve</span><br><p class="text-bold text-uppercase">Initial</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.adminTransferPending:
          statusEl =
            '<span class="text-xs text-warning">Approve</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.adminTransferSuccess:
          statusEl = '<p class="text-bold text-uppercase text-success">Approved</p>'
          break
        case SWAP_TOKO_USER_TRANSFER_STATUS.adminTransferFailed:
          statusEl =
            '<span class="text-xs text-error">Approve</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
      }

      return statusEl
    },

    formatSwapTokoStakingStatus(status) {
      let statusEl

      switch (status) {
        case SWAP_TOKO_STAKING_STATUS.invalid:
          statusEl =
            '<span class="text-xs">Request</span><br><p class="text-bold text-uppercase text-error">Invalid</p>'
          break
        case SWAP_TOKO_STAKING_STATUS.claimInit:
          statusEl = '<p class="text-bold text-uppercase text-success">Need Review</p>'
          break
        case SWAP_TOKO_STAKING_STATUS.adminReject:
          statusEl = '<p class="text-bold text-error text-uppercase">Rejected</p>'
          break
        case SWAP_TOKO_STAKING_STATUS.adminApprove:
          statusEl = '<span class="text-xs">Approve</span><br><p class="text-bold text-uppercase">Initial</p>'
          break
        case SWAP_TOKO_STAKING_STATUS.adminTransferPending:
          statusEl =
            '<span class="text-xs text-warning">Approve</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case SWAP_TOKO_STAKING_STATUS.adminTransferSuccess:
          statusEl = '<p class="text-bold text-uppercase text-success">Approved</p>'
          break
        case SWAP_TOKO_STAKING_STATUS.adminTransferFailed:
          statusEl =
            '<span class="text-xs text-error">Approve</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
      }

      return statusEl
    }
  }
}
