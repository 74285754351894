<template>
  <div>
    <div class="d-flex">
      <h1 class="headline--md text-bold text-uppercase">Claim Bonus requests</h1>
      <button
        v-if="currentUser && $auth('Page', currentUser, $accessRule.SwapTokoTrigger).canView() && shouldTriggerData"
        class="btn btn--primary"
        @click="isConfirmMigrateData = true"
      >
        Migrate Data
      </button>
    </div>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <selection-box
        v-model="params.status"
        :options="claimStatusOptions"
        :is-align-right="true"
        :on-check="onGetSwapTokoStakings"
        selected-box-class="is-lg search-box__filter"
      />

      <div class="d-flex">
        <search-box
          v-model="search"
          holder-text="Search by Wallet Address"
          class="mr-8"
          @on-search="onGetSwapTokoStakings"
        />

        <button class="btn btn--md btn--primary mr-16" @click="onGetSwapTokoStakings">Search</button>

        <selection-box v-model="params.perPage" :options="pagerOptions" :is-align-right="true" />
      </div>
    </div>

    <div
      v-if="!shouldTriggerData && swapTokoStakings && swapTokoStakings.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Wallet Address</th>
            <th>Amount<br />(TOKO)</th>
            <th>Joined<br />Plan</th>
            <th>Transfer<br />Status</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            v-for="swap in formatSwapTokoStakingsData"
            :key="swap.id"
            :to="{ name: 'SwapClaimBonus' }"
            tag="tr"
            class="link--clone"
          >
            <td :class="{ 'badge badge--triangle-shape tooltip': swap.user.blacklist > 0 }">
              {{ swap.user.email }}
              <span class="tooltip__text" v-if="swap.user.blacklist > 0">{{
                swap.user.blacklist | format_blacklist_type
              }}</span>
            </td>
            <td class="clipboard-copy wr-25">
              <span class="text-ellipsis clipboard-copy__text">
                <span class="clipboard-copy__text-inner">{{ swap.userWalletAddress }}</span>
                <copy-clipboard
                  @on-copy="doCopy($event)"
                  :external-link="`/etherscan-address/${swap.userWalletAddress}`"
                />
              </span>
            </td>
            <td>{{ swap.withdraw_amount | float_number_with_commas }}</td>
            <td>{{ swap.feStakingData && swap.feStakingData.plan_name }}</td>
            <td v-html="formatSwapTokoStakingStatus(swap.status)" />
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ swapTokoStakings.total | format_transaction_count_text }} in total</p>

        <pagination
          v-if="isNextPage(swapTokoStakings.total)"
          v-model="page"
          :page-count="getPageCount(swapTokoStakings.totalPages, swapTokoStakings.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <confirm-dialog
      v-if="isConfirmMigrateData"
      :open.sync="isConfirmMigrateData"
      confirmButtonText="Process"
      confirmButtonClass="text-purple"
      @confirm="onTriggerSwapTokoStakings"
    >
      <toko-warning-icon /><br />
      <p class="headline--md text-error">Once Time Action!</p>
      <p>We will process to migrate user able to claim bonus from Staking V1.</p>
      <p>Are you sure to continue?</p>
    </confirm-dialog>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { PAGER_OPTIONS, SWAP_TOKO_CLAIM_STAKING_OPTIONS } from '@/utils/data-sets'
import pagination from '@/mixins/pagination'
import handleAssets from '@/mixins/handle-assets'
import momentltsFormat from '@/mixins/momentlts-format'
import formatSwapTokoStatus from '@/mixins/swap-toko-status'
import formatStakingStatus from '@/mixins/staking-status'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
const SelectionBox = () => import('@/components/SelectionBox')
const Pagination = () => import('@/components/Pagination')
const ConfirmDialog = () => import('@/components/ConfirmDialog')
const TokoWarningIcon = () => import('@/ui/toko-icons/TokoWarning')
const SearchBox = () => import('@/components/SearchBox')
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  data() {
    return {
      params: {
        status: 0,
        perPage: 20
      },
      search: '',
      claimStatusOptions: SWAP_TOKO_CLAIM_STAKING_OPTIONS,
      page: 1,
      pagerOptions: PAGER_OPTIONS,
      isConfirmMigrateData: false,
      shouldTriggerData: false
    }
  },

  mixins: [
    pagination,
    handleAssets,
    momentltsFormat,
    formatSwapTokoStatus,
    formatStakingStatus,
    toggleLoading,
    copyClipboard
  ],

  computed: {
    ...mapState('swapTokoStaking', ['swapTokoStakings', 'assets']),

    ...mapGetters('swapTokoStaking', ['formatSwapTokoStakingsData']),

    ...mapState('smartContract', ['stakeSmartContract']),

    ...mapState('staking', ['stakingPlans'])
  },

  components: {
    SelectionBox,
    Pagination,
    ConfirmDialog,
    TokoWarningIcon,
    SearchBox,
    CopyClipboard
  },

  methods: {
    ...mapActions('swapTokoStaking', ['getSwapTokoStakings', 'triggerSwapTokoStakings']),

    ...mapActions('staking', ['getStakingPlans']),

    ...mapMutations('swapTokoStaking', ['CLEAR_SWAP_TOKO_STAKINGS_DATA']),

    shouldTriggerSwapTokoStakings() {
      if (this.swapTokoStakings && this.swapTokoStakings.data && this.swapTokoStakings.data.length) {
        this.$lStorage.set('triggerSwapTokoStakings', true)
        return (this.shouldTriggerData = false)
      } else {
        if (this.$lStorage.get('triggerSwapTokoStakings')) {
          return (this.shouldTriggerData = false)
        }
      }

      this.shouldTriggerData = true
    },

    onTriggerSwapTokoStakings() {
      this.toggleLoading()

      this.triggerSwapTokoStakings()
        .then(() => {
          this.shouldTriggerData = false
        })
        .finally(() => {
          this.toggleLoading()
          this.isConfirmMigrateData = false
        })
    },

    onGetSwapTokoStakings() {
      this.toggleLoading()
      this.page = 1
      this.getSwapTokoStakings(this.getSwapTokoStakingsRequestParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getSwapTokoStakingsRequestParams() {
      return {
        page: this.page,
        limit: this.params.perPage,
        status: this.params.status > 0 ? this.params.status : null,
        userWalletAddress: this.search
      }
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getSwapTokoStakings(this.getSwapTokoStakingsRequestParams()).finally(() => {
        this.toggleLoading()
      })
    }
  },

  async created() {
    this['CLEAR_SWAP_TOKO_STAKINGS_DATA']()

    try {
      if (!this.stakingPlans.plans.length) await this.getStakingPlans()
      await this.getSwapTokoStakings(this.getSwapTokoStakingsRequestParams())
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.shouldTriggerSwapTokoStakings()
    this.toggleLoading()
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'SwapClaimBonusDetail') {
      this.storeAssets('swapTokoStaking')
      next()
    } else {
      this.clearAssets('swapTokoStaking')
      next()
    }
  }
}
</script>
